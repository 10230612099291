<ng-template #PrintQrModal let-c="close" let-d="dismiss" class="modal-dialog" let-modal>
  <div class="modal-header">
    <h4 class="modal-title flex-grow-1 text-center">
      {{ 'ASSET.Page set up'|translate}}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body py-4">
    <div class="row custom-box">
      <div class="col-12">
        <label>
          {{'ASSET.Paper Size' | translate}}
        </label>
        <ng-select [items]="paperSize" *ngIf="!loadPaperSize"
                   class="custom-grey-dropdown"
                   [bindLabel]="'label'"
                   bindValue="value"
                   placeholder="{{'ASSET.Paper Size' | translate}}"
                   [(ngModel)]="paperSizeData">
        </ng-select>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-outline btn-confirm"
            [style.--c]="themeList?.header_table_color"
            [style.--bg]="'#fff'"
            (click)="modal.dismiss('Cross click')">
      {{"MEMOS.CANCEL" | translate}}
    </button>

    <a href="javascript:void(0)"
       [ngClass]="{ 'disable-pointer-event': !paperSizeData}"
       (click)="printingQrCode()">
      <button class="btn btn-confirm"
              appSetTheme
              type="button"
              [disabled]="!paperSizeData"
              [ladda]="printingPdf">
        {{"MEMOS.CONFIRM" | translate}}
      </button>
    </a>
  </div>
</ng-template>
