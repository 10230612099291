import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { HomeComponent } from './core/components/home/home.component';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { LoginComponent } from './core/components/login/login.component';
import { AdfsCallbackComponent } from './core/components/adfs-callback/adfs-callback.component';
import { FinanceDocumentModule } from './modules/finance-document/finance-document.module';
import { MaintenanceComponent } from './core/components/maintenance/maintenance.component';
import { Component } from '@angular/core';
import { PermissionNotFoundComponent } from './shared/components/permission-not-found/permission-not-found.component';

@Component({
  selector: 'app-media-window-component',
  template: 'file is in download folder',
  styleUrls: [],
})
class MediaWindowComponent {}
const routes: Routes = [
  {
    canActivate: [AuthGuard],
    data: [
      'General',
      'IT',
      'Master Admin',
      'Admin',
      'Finance Admin',
      'SSC',
      'User Management',
    ],
    path: '',
    component: HomeComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'adfs-callback',
    component: AdfsCallbackComponent,
  },
  {
    path: 'window-media',
    component: MediaWindowComponent,
  },
  {
    path: 'profile-detail',
    data: [
      'General',
      'IT',
      'Master Admin',
      'Admin',
      'Finance Admin',
      'SSC',
      'User Management',
    ],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/profile/profile.module').then(
        (m) => m.ProfileModule,
      ),
  },
  {
    path: 'news-feed',
    data: [
      'General',
      'IT',
      'Master Admin',
      'Admin',
      'Finance Admin',
      'SSC',
      'User Management',
    ],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/new-feed/new-feed.module').then(
        (m) => m.NewFeedModule,
      ),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('../app/modules/register/register.module').then(
        (m) => m.RegisterModule,
      ),
  },
  {
    path: 'configuration',
    data: ['Master Admin', 'Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/setting/setting.module').then(
        (m) => m.SettingModule,
      ),
  },
  {
    path: 'users',
    data: [
      'Master Admin',
      'Admin',
      'IT',
      'Finance Admin',
      'User Management',
    ],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/users/users.module').then(
        (m) => m.UsersModule,
      ),
  },
  {
    path: 'department',
    data: [
      'Master Admin',
      'Admin',
      'IT',
      'Finance Admin',
      'User Management',
    ],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/department/department.module').then(
        (m) => m.DepartmentModule,
      ),
  },
  {
    path: 'manage-types-upload-memo',
    data: ['Master Admin', 'Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/manage-types-upload-memo/manage-types-upload-memo.module'
      ).then((m) => m.ManageTypesUploadMemoModule),
  },
  {
    path: 'manage-csr',
    data: ['Master Admin', 'Admin', 'IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/manage-csr/manage-csr.module').then(
        (m) => m.ManageCsrModule,
      ),
  },
  {
    path: 'kpi-dashboard',
    data: ['Master Admin', 'Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule,
      ),
  },
  {
    path: 'usage-dashboard',
    data: ['Admin', 'Master Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/usage/usage.module').then(
        (m) => m.UsageModule,
      ),
  },
  {
    path: 'memo-report',
    data: ['Admin', 'Master Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/memo-report/memo-report.module').then(
        (m) => m.MemoReportModule,
      ),
  },
  {
    path: 'operation-log',
    data: ['Admin', 'Master Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/operation-log/operation-log.module'
      ).then((m) => m.OperationLogModule),
  },
  {
    path: 'pending-approval',
    data: ['General', 'Finance Admin', 'IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/executive/executive.module').then(
        (m) => m.ExecutiveModule,
      ),
  },
  {
    path: 'approval',
    loadChildren: () =>
      import('../app/modules/approval/approval.module').then(
        (m) => m.ApprovalModule,
      ),
  },
  {
    path: 'loa',
    data: ['Master Admin', 'Admin', 'IT', 'Finance Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/loa/loa.module').then(
        (m) => m.LoaModule,
      ),
  },
  {
    path: 'cc-group',
    data: ['Master Admin', 'Admin', 'IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/cc-group/cc-group.module').then(
        (m) => m.CcGroupModule,
      ),
  },
  {
    path: 'memos',
    data: [
      'General',
      'Finance Admin',
      'SSC',
      'Master Admin',
      'Admin',
      'IT',
    ],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/memos/memo.module').then((m) => m.MemoModule),
  },
  {
    path: 'certificate',
    data: ['Master Admin', 'Admin', 'IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/certificate/certificate.module').then(
        (m) => m.CertificateModule,
      ),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import(
        '../app/modules/reset-password/reset-password.module'
      ).then((m) => m.ResetPasswordModule),
  },
  {
    path: 'master-data',
    data: ['Master Admin', 'Admin', 'IT', 'Finance Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/master-data/master-data.module').then(
        (m) => m.MasterDataModule,
      ),
  },
  {
    path: 'finance-document',
    data: ['Finance Admin', 'SSC'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/finance-document/finance-document.module'
      ).then((m) => m.FinanceDocumentModule),
  },
  {
    path: 'fix-asset',
    data: ['Finance Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/fix-asset-register/fix-asset-register.module'
      ).then((m) => m.FixAssetRegisterModule),
  },
  {
    path: 'small-equipment',
    data: ['Finance Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/small-equipment/small-equipment.module'
      ).then((m) => m.SmallEquipmentModule),
  },
  {
    path: 'landing',
    loadChildren: () =>
      import(
        '../app/modules/landding-page/asset-landing-page.module'
      ).then((m) => m.AssetLandingPageModule),
  },
  {
    path: 'transfer-document',
    data: ['Finance Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/transfer-document/transfer-document.module'
      ).then((m) => m.TransferDocumentModule),
  },
  {
    path: 'write-off',
    data: ['Finance Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/write-off-document/write-off-document.module'
      ).then((m) => m.WriteOffDocumentModule),
  },
  {
    path: 'physical-check',
    data: ['Finance Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/physical-check-document/physical-check-document.module'
      ).then((m) => m.PhysicalCheckDocumentModule),
  },
  {
    path: 'it-equipment',
    data: ['IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/it-equipment/it-equipment.module').then(
        (m) => m.ItEquipmentModule,
      ),
  },
  {
    path: 'lost-equipment',
    data: ['IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/approve-request-lost-equipment/approve-request-lost-equipment.module'
      ).then((m) => m.ApproveRequestLostEquipmentModule),
  },
  {
    path: 'ma-equipment',
    data: ['IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/ma-equipment/ma-equipment.module').then(
        (m) => m.MaEquipmentModule,
      ),
  },
  {
    path: 'movement',
    data: ['IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/movement-document/movement-document.module'
      ).then((m) => m.MovementDocumentModule),
  },
  {
    path: 'borrow',
    data: ['IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/borrow-document/borrow-document.module'
      ).then((m) => m.BorrowDocumentModule),
  },
  {
    path: 'write-off-it',
    data: ['IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/write-off-it-document/write-off-it-document.module'
      ).then((m) => m.WriteOffItDocumentModule),
  },
  {
    path: 'maintenance-document',
    data: ['IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/maintenance-document/maintenance-document.module'
      ).then((m) => m.MaintenanceDocumentModule),
  },
  {
    path: 'return-document',
    data: ['IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/return-document/return-document.module'
      ).then((m) => m.ReturnDocumentModule),
  },
  {
    path: 'it-dashboard',
    data: ['IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/it-dashboard/it-dashboard.module').then(
        (m) => m.ItDashboardModule,
      ),
  },
  {
    path: 'alert-setting',
    data: ['Master Admin', 'Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/alert-setting/alert-setting.module'
      ).then((m) => m.AlertSettingModule),
  },
  { path: 'website-maintenance', component: MaintenanceComponent },

  { path: 'website-maintenance', component: MaintenanceComponent },

  { path: 'website-maintenance', component: MaintenanceComponent },

  // otherwise redirect to home
  { path: 'page-not-found', component: PageNotFoundComponent },
  {
    path: 'permission-not-found',
    component: PermissionNotFoundComponent,
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
