import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/http/api.service';
import { API_URL } from './api.constant';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadMemoTypeService {
  baseUrl = environment.baseUrl;

  constructor(
    private http: ApiService,
    private httpClient: HttpClient,
  ) {}

  getUploadMemoTypeList(data?) {
    return this.http.get(API_URL.upload_memo_type, data);
  }

  addUploadMemoType(data) {
    return this.http.post(API_URL.upload_memo_type, data);
  }

  deleteUploadMemoType(id) {
    return this.http.delete(API_URL.upload_memo_type + id + '/');
  }

  editUploadMemoType(id, data) {
    return this.http.patch(API_URL.upload_memo_type + id + '/', data);
  }

  batchUploadExample(type: string): Observable<HttpResponse<string>> {
    return this.httpClient.get<string>(
      this.baseUrl + API_URL.batch_uploads + type + '/',
      {
        headers: this.http.requestHeader(),
        responseType: 'blob' as 'json',
        observe: 'response',
      },
    );
  }

  batchUploadExampleForAssetList(
    type: string,
  ): Observable<HttpResponse<string>> {
    return this.httpClient.get<string>(
      this.baseUrl + `/api/asset/${type}/example-excel`,
      {
        headers: this.http.requestHeader(),
        responseType: 'blob' as 'json',
        observe: 'response',
      },
    );
  }
  batchUploadExampleForMemoType(
    memoType: string,
  ): Observable<HttpResponse<string>> {
    return this.httpClient.get<string>(
      this.baseUrl + `/api/${memoType}/example-excel`,
      {
        headers: this.http.requestHeader(),
        responseType: 'blob' as 'json',
        observe: 'response',
      },
    );
  }

  batchUploadExampleItEquipment(
    type: string,
    api: string,
  ): Observable<HttpResponse<string>> {
    return this.httpClient.get<string>(
      this.baseUrl + `/api/${type}/${api}`,
      {
        headers: this.http.requestHeader(),
        responseType: 'blob' as 'json',
        observe: 'response',
      },
    );
  }
}
