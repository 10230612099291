import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concat, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../core/http/api.service';
import { DropdownItem } from '../models/common.model';
import { API_URL } from './api.constant';
import { MasterDataDropDowns } from '../../modules/master-data/shared/master-data.model';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { ApproverDropdown } from '../../modules/memos/model/memo.model';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  constructor(
    private http: HttpClient,
    private httpClient: ApiService,
  ) {}

  getDropdown(
    params,
  ): Observable<{ [dropdownName: string]: DropdownItem[] }> {
    return this.httpClient.get<{
      [dropdownName: string]: DropdownItem[];
    }>(API_URL.dropdown, params);
  }

  getDropdownWithHeader(header, params) {
    return this.http.get(environment.baseUrl + API_URL.dropdown, {
      params,
      headers: header,
    });
  }

  getMasterData(params?): Observable<MasterDataDropDowns> {
    return this.http.get<MasterDataDropDowns>(
      environment.baseUrl + API_URL.master_data_names,
      { params },
    );
  }

  getFinanceAdminAndGeneral(): Observable<{
    data: { id: number; name: string }[];
  }> {
    return this.http.get<{ data: { id: number; name: string }[] }>(
      environment.baseUrl + API_URL.general_finance_admin,
    );
  }

  memoTypeItem() {
    return [
      // {
      //   label: 'Internal',
      //   value: 'internal',
      //   context: {
      //     display_name: 'แบบขออนุมัติภายใน',
      //     display_name_en: 'Internal',
      //   },
      // },
      // {
      //   label: 'External',
      //   value: 'external',
      //   context: {
      //     display_name: 'แบบขออนุมัติภายนอก',
      //     display_name_en: 'External',
      //   },
      // },
      // {
      //   label: 'Purchase Request',
      //   value: 'purchase_request',
      //   context: {
      //     display_name: 'แบบขออนุมัติการซื้อ',
      //     display_name_en: 'Purchase Request',
      //   },
      // },
      {
        label: 'E-Payment Request',
        value: 'e_payment',
        context: {
          display_name: 'แบบขอชำระเงินอิเล็กทรอนิกส์',
          display_name_en: 'E-Payment Request',
        },
      },
      {
        label: 'New Hire and Requisition Form',
        value: 'new_hire',
        context: {
          display_name: 'แบบฟอร์มแจ้งขอพนักงานอย่างเป็นทางการ',
          display_name_en: 'New Hire and Requisition Form',
        },
      },
      {
        label: 'Transfer Document',
        value: 'transfer',
        context: {
          display_name: 'เอกสารการเคลื่อนย้าย',
          display_name_en: 'Transfer Document',
        },
      },
      {
        label: 'Write-Off',
        value: 'write_off',
        context: {
          display_name: 'เอกสารการถอดสินทรัพย์',
          display_name_en: 'Write-Off',
        },
      },
      {
        label: 'Physical Check',
        value: 'physical_check',
        context: {
          display_name: 'เอกสารตรวจนับสินทรัพย์',
          display_name_en: 'Physical Check',
        },
      },
      {
        label: 'MA Document',
        value: 'ma',
        context: {
          display_name: 'เอกสารบำรุงรักษา',
          display_name_en: 'MA Document',
        },
      },
      {
        label: 'Renew MA Document',
        value: 'renew_ma',
        context: {
          display_name: 'เอกสารต่ออายุบำรุงรักษา',
          display_name_en: 'Renew MA Document',
        },
      },
      {
        label: 'Movement Document',
        value: 'movement',
        context: {
          display_name: 'เอกสารการเคลื่อนย้าย',
          display_name_en: 'Movement Document',
        },
      },
      {
        label: 'Upload',
        value: 'upload',
        context: {
          display_name: 'เอกสารอัปโหลด',
          display_name_en: 'Upload',
        },
      },
    ];
  }

  setPeople(
    input$: Observable<string>,
    list: any,
    loading: boolean,
  ): Observable<any[]> {
    return concat(
      of([] as any[]),
      input$.pipe(
        distinctUntilChanged(),
        filter((term) => term != null && term !== ''),
        debounceTime(300),
        tap(() => (loading = true)),
        switchMap((query) => {
          const params: { [k: string]: any } = {
            type: 'people',
            all: 'true',
            group: 'General,Finance Admin,IT',
            query,
          };
          return this.getDropdown(params).pipe(
            map((obj) =>
              obj.people.map((people: DropdownItem<number>) => {
                list = {
                  label: people.label,
                  value: people.value,
                };
                return list;
              }),
            ),
          );
        }),
        catchError(() => of([] as ApproverDropdown[])),
        tap(() => (loading = false)),
      ),
    );
  }
}

export interface DropdownResponse {
  [type: string]: {
    label: any;
    value: any;
    context?: any;
  }[];
}
