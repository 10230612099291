import { Injectable } from '@angular/core';
import { ApiService } from '../../core/http/api.service';

@Injectable({
  providedIn: 'root',
})
export class QrCodeService {
  constructor(private http: ApiService) {}
  getMultiQrCodeToPrint(apiPath, data) {
    return this.http.post(
      `/api/${apiPath}/print-multi-qr-code/`,
      data,
    );
  }
}
