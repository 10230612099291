import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(value: string, args?: any): any {
    if (!value) {
      // add null check
      return value;
    }
    if (value.charAt(1) === '2') {
      return (
        {
          9: value.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3'),
        }[value.length] || value
      );
    }
    return (
      {
        10: value?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
      }[value?.length] || value
    );
  }
}
