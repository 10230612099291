import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ThemeList, ThemeService } from '../../service/theme.service';
import { finalize } from 'rxjs/operators';
import { QrCodeService } from '../../service/qr-code.service';
import { AlertService } from '../../../core/services/alert.service';
import {
  DropdownResponse,
  DropdownService,
} from '../../service/dropdown.service';

@Component({
  selector: 'app-print-multiple-qr-code',
  templateUrl: './print-multiple-qr-code.component.html',
  styleUrls: ['./print-multiple-qr-code.component.scss'],
})
export class PrintMultipleQrCodeComponent implements OnInit {
  @ViewChild('PrintQrModal', { static: true })
  printQrCode: ElementRef;
  paperSize = [];
  loadPaperSize = false;
  paperSizeData = null;

  loadAmountSize = false;
  amountPaperData = null;
  printingPdf = false;
  themeList: ThemeList;
  @Output() sendPaperSize = new EventEmitter();
  @Input() selectedDocument: number[];
  @Input() path: string;
  @Input() key = 'asset_list';
  constructor(
    public modalService: NgbModal,
    private themeService: ThemeService,
    private qrCodeService: QrCodeService,
    private alert: AlertService,
    private dropdownService: DropdownService,
  ) {
    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
  }

  ngOnInit(): void {
    this.intitialPaperSize();
  }

  showPrintQrCodeModal(): void {
    this.paperSizeData = null;
    this.modalService.open(this.printQrCode, {
      backdrop: 'static',
      size: 'md',
      keyboard: false,
      centered: true,
    });
  }

  intitialPaperSize(): void {
    this.loadPaperSize = true;
    const params = {
      type: 'paper_size',
    };
    this.dropdownService.getDropdown(params).subscribe(
      (dropdown: DropdownResponse) => {
        this.paperSize = dropdown.paper_size.filter(
          (item) => !['a0', 'a1'].includes(item.value),
        );
        this.loadPaperSize = false;
      },
      (error) => {
        this.alert.error(error.status);
      },
    );
  }

  printingQrCode(): void {
    const body = {
      [this.key]: this.selectedDocument,
      qr_code_size: this.paperSizeData,
    };
    this.printingPdf = true;
    this.qrCodeService
      .getMultiQrCodeToPrint(this.path, body)
      .pipe(
        finalize(() => {
          this.printingPdf = false;
          this.modalService.dismissAll();
        }),
      )
      .subscribe(
        (res: any) => {
          this.sendPaperSize.emit(res.pdf);
        },
        (error) => {
          this.alert.error(error.status);
        },
      );
  }
}
